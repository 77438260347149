import Lottie from 'lottie-react'
import { Text, Box, Flex, textDecoration } from '@chakra-ui/react'
import {useContext} from 'react'
import { motion } from 'framer-motion'

import VPContext from '../others/VPContext'
import nothingFound from '../../media/animations/nothingFound.json'
import { RequestItemButton } from '../elements/accentButtons'

export default function NothingFound() {

    const viewPort = useContext(VPContext)

    const messageStyle = {
        fontSize: viewPort?'x-large':'large',
        fontWeight: '600',
        color: 'gray'
    }
    const messageStyle2 = {
        fontSize: viewPort?'medium':'small',
        textDecoration:"underline",
        fontWeight: '400',
        color: 'rgb(0,0,0,0.5)'
    }

    return (
        <motion.div
        initial={{opacity:0}}
        animate={{opacity:1, transition:{duration:1}}}>
            <Box display={'flex'} flexDirection={viewPort?'row':'column'} justifyContent={'center'} alignItems={'center'} gap='5px'>
                <Box width={viewPort?'35%':'70%'}>
                    <Lottie animationData={nothingFound} />
                </Box>
                <Flex flexDirection={'column'}>
                <Text style={messageStyle}>No available requests at this moment...</Text>

                <br />
                </Flex>
            </Box>
                <Box display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'} gap='5px'>
                    <Text style={messageStyle2}>trying to order instead ?</Text>
                    <RequestItemButton />
                </Box>
            <br />
        </motion.div>
    )
}