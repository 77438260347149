import './App.css';

import { Routes, Route } from "react-router-dom";
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion'
import { Center, Spinner } from '@chakra-ui/react';

import LoginPage from './components/pages/loginPage'
import RegisterPage from './components/pages/registerPage';
import QRedirect from './components/pages/QRedirect';
import HomePage from './components/pages/homePage';
import MyAccount from './components/sections/myAccount';
import MyErrands from './components/sections/myErrands';
import MyRequests from './components/sections/myRequests';
import NewRequest from './components/sections/newRequest';
import AvailableRequests from './components/sections/availableRequests'
import ResetPW from './components/pages/resetPW';
import ChangePW from './components/pages/changePW';
import Verify from './components/pages/verify';
import Info from './components/pages/info';
import Tnc from './components/pages/tnc';
import Promo1 from './components/extras/promo1';

function App() {

  const location = useLocation()

  async function checkLoginStatus() {
    try {
      const response = await fetch(process.env.REACT_APP_BACKEND_URL+'/api/validateToken', {
          method: "POST",
          headers: {
              'Content-Type': 'application/json',
          },
          credentials: 'include'
      })
      if (response.status === 200) {
          const validity = await response.json()
          return validity
      }
      else return false
    } catch(e) {return false}
  }

  function handleQRRedirect() {
    if(window.sessionStorage.qrplc && window.location.pathname !== '/places') window.location.href = '/places'
  }

  function Dummy(props) {
    const [loginState, setLoginState] = useState(0)// 0 for nothing, 1 for destination
    useEffect(()=>{
      const logInVerification = async ()=>{
        // temporary removed verification to speed up things and test out security
        // const loggedIn = true
        const loggedIn = await checkLoginStatus()
        if(loggedIn){
          if(loggedIn.validity){
            if(loggedIn.verified){
              handleQRRedirect()
              setLoginState(1)
            } else window.location.href='/verify'
          }
          else window.location.href='/login'
        } else window.location.href='/login'
      }
      logInVerification()
    },[])

    return (
      <>
        {loginState?
          props.element
        :
        <Center height={'100%'}><Spinner size='xl'/></Center>
        }
      </>
    )
  }

  return (
    <div style={{height:'100%', borderRadius:"22.5px", backgroundColor:'white'}} className='App'>
      <AnimatePresence>
        <Routes location={location} key={location.pathname}>
          <Route path='/' element = {<Dummy element={<HomePage/>}/>} />
          <Route path='/login' element = {<LoginPage/>} />
          <Route path='/register' element = {<RegisterPage/>} />
          <Route path='/qr' element = {<QRedirect/>} />
          <Route path='/myAccount' element = {<Dummy element={<MyAccount/>}/>} />
          <Route path='/errands' element = {<Dummy element={<MyErrands/>}/>} />
          <Route path='/requests' element = {<Dummy element={<MyRequests/>}/>} />
          <Route path='/newRequest' element = {<Dummy element={<NewRequest/>}/>} />
          <Route path='/places' element = {<Dummy element={<AvailableRequests/>}/>} />
          <Route path='/resetpw' element = {<ResetPW/>} />
          <Route path='/setpw' element = {<ChangePW/>} />
          <Route path='/verify' element = {<Verify/>} />
          <Route path='/info' element = {<Dummy element={<Info/>}/>} />
          <Route path='/tnc' element = {<Tnc/>} />

          <Route path='/promo1' element={<Promo1/>} />
        </Routes>
      </AnimatePresence>
    </div>
  );
}

export default App;
