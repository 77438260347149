import { Text, VStack, Box, Divider } from "@chakra-ui/react";
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRoute, faUserClock, faHeartCirclePlus, faHandshakeAngle, faUser, faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { motion } from "framer-motion";

import { useContext } from "react";
import VPContext from "../../../others/VPContext";
import vibFn from '../../../others/vibration'


function ButtonFormat(props) {
    const viewPort = useContext(VPContext)
    const textStyling = {
        // fontWeight: '600',
        fontSize: viewPort?'0.8em':'1.3vh',
        // fontSize: viewPort?'0.65em':'3vw',
        padding: 0,
        margin: 0,
        textWrap: 'nowrap',
        // color:"rgba(256,256,256,0.75)"
        color:'white'
    }

    function ActiveButton() {
        return (
            <>
            <motion.div 
                initial={{y:'100%', scale:0}}
                animate={{y:'0', scale:1, transition:{duration:0.35}}}
                // exit={{y:'100%', transition:{duration:2}}}
                style={{width:'150%'}}
            >
                <Divider width='60%' margin='auto' border='2px solid cyan' borderRadius={'5px'} />
            </motion.div>
            </>
        )
    }

    return (
        <Box 
        onClick={()=>vibFn(1)}
        style={{height:'100%'}} >
        <Link to={props.link}>
            <motion.div
                style={{height:'100%'}}
                whileHover={{scale:0.9, color:'darkgray'}}
                whileTap={{scale:1.15}}
            >
                <VStack style={{height:'100%'}} justifyContent={'center'} gap='0'>
                    <FontAwesomeIcon icon={props.icon} color='rgba(256,256,256,0.9)' fontSize={viewPort?'3vh':'3vh'} />
                    <Text style={textStyling} >{props.text}</Text>
                    {
                        (window.location.pathname!==props.link)?'':
                        <ActiveButton/>
                    }
                </VStack>
            </motion.div>
        </Link>
        </Box>
    )
}

export function ErrandsButton() {
    return (
        <ButtonFormat icon={faRoute} text='My Errands' link={'/errands'} />
    )
}

export function RequestsButton() {
    return (
        <ButtonFormat icon={faUserClock} text='My Requests' link={'/requests'} />
    )
}
export function NewRequestButton() {
    return (
        <ButtonFormat icon={faHeartCirclePlus} text='Request Item' link={'/newRequest'} />
    )
}

export function PlacesButton() {
    return (
        <ButtonFormat icon={faHandshakeAngle} text='Deliver Items' link={'/places'} />
    )
}

export function UserButton() {
    return (
        <ButtonFormat icon={faUser} text='Account' link={'/myAccount'} />
    )
}

export function InfoButton() {
    return (
        <ButtonFormat icon={faInfoCircle} text='About' link={'/info'} />
    )
}