import { Box, Flex } from "@chakra-ui/react"
import { motion } from "framer-motion"
import { useContext } from "react"

import { UserButton, InfoButton } from "./actionButtons"
import LogoutButton from "./logoutButton"

import { UITheme } from "../../../others/colors"
import MenuContext from "../../../others/MenuContext"

export default function MenuOverlay() {
    const {overlayState,toggleOverlay} = useContext(MenuContext)

    const overlayStyle = {
        backgroundColor: 'rgba(0,0,0,0.3)',
        zIndex: '10', 
        height: '100%',
        width: '100%',
        position: 'absolute',
        overflowY: 'hidden'
    }
    function DropDown() {

        function ButtonCover(props){
            return (
                <motion.div
                    initial={{scale:0}}
                    animate={{scale:1, transition: {duration:0.3, type: "spring", stiffness: 60}}} >
                    {props.children}
                </motion.div>
            )
        }

        return(
            <motion.div
            initial={{
                // height:0,
                scale:0,
                y: '-100%',
                x: window.innerWidth
            }}
            animate={{
                // height:'unset',
                scale:1,
                x:0,
                y:0,
                transition: {duration:0.02, type: "spring", stiffness: 500, damping:28}
            }}
            style={{
                width:'100%',
                color:'white',
                padding:'5px 5px 15px 15px',
                display:'flex', justifyContent:'flex-end',
            }}
            >
                <Flex
                style={{
                    // width:'50%',
                    backgroundColor: UITheme(true).navBar,
                    justifyContent:'flex-end',
                    padding:'10px 30px 10px 30px',
                    borderRadius:'35px 0px 35px 35px'
                }}
                >
                    <motion.div layout>
                        <Flex flexDirection={'row'} alignItems={'center'} justifyContent={'center'} gap='30px' >
                            <ButtonCover>
                                <InfoButton/>
                            </ButtonCover>
                            <ButtonCover>
                                <UserButton/>
                            </ButtonCover>
                            <ButtonCover>
                                <LogoutButton/>
                            </ButtonCover>
                        </Flex>
                    </motion.div>
                </Flex>
            </motion.div>
        )
    }
    return (
        <motion.div
        initial ={{
            backdropFilter: 'blur(0px)',
            WebkitBackdropFilter: 'blur(0px)'
        }}
        animate ={{
            backdropFilter: 'blur(8px)',
            WebkitBackdropFilter: 'blur(8px)',
            transition: {duration: 0.2}
        }}
         onClick={toggleOverlay} style={overlayStyle}>
            <Box height={'6vh'} />
            <DropDown/>
        </motion.div>
    )
}